import React from 'react'
import styled from '@emotion/styled'

import UserFeedbackBanner from './UserFeedbackBanner'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Icon from '../../components/Icon'
import Button from '../../components/Button'
import {
  laptopContainerStyle,
  mobileContainerStyle
} from '../../components/Common'
import { H1M, P } from '../../styleElements'
import mq from '../../utils/mediaQuery'

const UserFeedbackPage = () => {
  const DETAIL_LIST = [
    {
      icon: 'location',
      label: 'Location',
      desc: <P>Remote (Zoom)</P>
    },
    {
      icon: 'time',
      label: 'Duration',
      desc: <P>1-hour</P>
    },
    {
      label: 'Time',
      desc: (
        <P>
          We’ll give you all the details if you’re invited to take part in a
          session.
        </P>
      )
    },
    {
      icon: 'perks',
      label: 'Perks',
      desc: <P>$50 USD and our endless gratitude!</P>
    }
  ]

  return (
    <Layout>
      <SEO title="Contact" />
      <UserFeedbackBanner />
      <InfoArea>
        <InfoContainer>
          <TitleLabel>
            USER <br></br> FEEDBACK
          </TitleLabel>
          <DescriptionText>
            PicCollage is an app for making fun and creative photo collages.
            With more than 200 million downloads, it is currently ranked top 10
            in photo app category in both the U.S. and the U.K. Now we want to
            invite you to visit PicCollage headquarter for user feedback
            interview!
          </DescriptionText>
        </InfoContainer>
      </InfoArea>
      <DetailArea>
        <DetailContainer>
          <DetailList>
            {DETAIL_LIST.map(detail => (
              <DetailItem key={detail.label}>
                <DetailHeader>
                  {detail.icon ? (
                    <DetailIcon name={detail.icon} width={24}></DetailIcon>
                  ) : (
                    <EmptyDetailIcon width={24} />
                  )}
                  <DetailTitle>{detail.label}</DetailTitle>
                </DetailHeader>
                <DetailContent>{detail.desc}</DetailContent>
              </DetailItem>
            ))}
          </DetailList>
          <Button
            type="outlineDark"
            to="https://piccollage.typeform.com/to/DxzVfUn6?utm_source=website"
            icon="next_active"
            activeIcon="next_white"
            external
          >
            Sign Up Now
          </Button>
        </DetailContainer>
      </DetailArea>
    </Layout>
  )
}

const InfoArea = styled.section`
  ${mobileContainerStyle}
  background-color: ${props => props.theme.color.gray4};


  ${mq.md} {
    ${laptopContainerStyle}
  }
`

const InfoContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;
  position: relative;

  ${mq.md} {
    max-width: 780px;
    margin: 0 auto;
    width: 80%;
  }
`

const TitleLabel = styled(H1M)`
  margin-bottom: 20px;

  ${mq.md} {
    margin-bottom: 0;
    position: absolute;
    transform: rotate(90deg);
    left: -170px;
    top: 20px;
    z-index: 1;
  }
`

const DescriptionText = styled(P)`
  font-weight: ${props => props.theme.fontWeight.bold};
`

const DetailArea = styled.div`
  ${mobileContainerStyle}
  background-color: ${props => props.theme.color.white};
  padding-top: 40px;
  padding-bottom: 100px;
`

const DetailContainer = styled.div`
  ${mq.md} {
    max-width: 780px;
    margin: 0 auto;
    width: 80%;
  }
`

const DetailList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`

const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const DetailHeader = styled.header`
  display: flex;
  align-items: center;
`

const DetailIcon = styled(Icon)`
  margin-right: 10px;
`

const EmptyDetailIcon = styled.div`
  margin-right: 10px;
  width: ${props => props.width}px;
  height: ${props => props.width}px;
`

const DetailTitle = styled(P)`
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.gray1};
`

const DetailContent = styled.div`
  padding-left: 35px;
`

export default UserFeedbackPage
